<template>
  <div class="common_sub_container">
    <h2>CARRIERS</h2>
    <section>
      <div class="two_tabs">
        <a
          @click="showTab1=true,showTab2=false"
          v-bind:class="{ active: showTab1 }"
          class="tab_buttons"
        >ADD</a>
        <a
          @click="showTab2=true,showTab1=false"
          v-bind:class="{ active: showTab2 }"
          class="tab_buttons"
        >VIEW</a>
      </div>
      <CarriersAdd v-if="showTab1" @redirect="showTab2=true,showTab1=false"/>
      <CarriersView v-if="showTab2" />
    </section>
  </div>
</template>
<script>
import CarriersAdd from "./Carriers_Add.vue";
import CarriersView from "./Carriers_View.vue";
export default {
  name: "x",
  components: {
    CarriersAdd,
    CarriersView
  },
  created(){
    this.$emit("breadCrumbData",["Master Data","Carriers"]);
  },
  data() {
    return {
      showTab1: true,
      showTab2: false
    };
  }
};
</script>
<style  scoped >
</style>